import { xhr } from "/premmio/public/modules/xhr/xhr.js";
import htmljs from "/premmio/htmljs/html.js";
import { ProductSummary } from "../premmio/public/components/product/productSummary.html.js";
import { getBooks } from "./books.scripts.js";
import { NewsPost } from "../components/newsPost/newsPost.html.js";
import { EpisodeBox } from "../components/episode/episode.html.js";
// import {
//   alphaNum,
//   numOnly,
// } from "../premmio/public/modules/formatString/formatString.js";
// import { progressBarComponent } from "../premmio/public/components/progressBar/progressBar.html.js";

const App = new htmljs();

export const getLatestEpisodes = () => {
  const success = (request) => {
    const episodes = JSON.parse(request.response);

    for (let show in episodes) {
      const episode = episodes[show],
        renderAreas = document.querySelectorAll(`.${show}Latest`);

      App.render(EpisodeBox(episode, true), null, (latestEpisode) => {
        renderAreas.forEach((renderArea) => {
          // create a copy of the latestEpisode
          const latestEpisodeCopy = latestEpisode.cloneNode(true);

          renderArea.appendChild(latestEpisodeCopy);
        });
      });
    }
  };

  xhr({ path: "/episodes/latest", success });
};

getLatestEpisodes();

const getLatestAnnouncement = () => {
  const success = (request) => {
    const newsPostData = JSON.parse(request.response),
      latestNewsPost = document.querySelector("#latestNewsPost");

    App.render(NewsPost(newsPostData), null, (newsPost) => {
      latestNewsPost.appendChild(newsPost);
    });
  };

  xhr({
    path: "/news/latest",
    success,
  });
};

getBooks();
getLatestAnnouncement();

// get data from kicktraq

// const getKicktraqProgress = () => {
//   const success = (request) => {
//     const response = JSON.parse(request.response),
//       goal = numOnly(response.goal),
//       total = numOnly(response.total),
//       percent = Math.floor((total / goal) * 100);

//     const progressBar = renderTemplate(
//       progressBarComponent(percent, `${response.total} of ${response.goal}`)
//     );

//     const progress = document.querySelector("#progress");
//     progress.innerHTML = "";
//     progress.appendChild(progressBar);
//   };

//   xhr({
//     path: "/kicktraq/projects/nat19/somnus-domina-retia-a-dandd-5th-edition-handbook/",
//     success,
//   });
// };

// getKicktraqProgress();
